import React, { useEffect, useState, lazy, Suspense, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { parse } from 'query-string';
import BackgroundImage from 'gatsby-background-image';
import styles from '../../utils/customStyles';
import StoreProvider, { StoreContext } from '../StoreContext';
import ConditionalWrapper from './ConditionalWrapper';
import { useLayoutQueries } from '../../utils/queries';
import '../../fonts/font.scss';
import './layout.scss';

const customStyles = styles();

const AdditionalFooterContent = lazy(() =>
  import('../Dynamic/AdditionalFooterContent')
);
const Footer = lazy(() => import('../Footer/Footer'));
const GlobalStyle = lazy(() => import('../../utils/GlobalStyle'));
const CustomHomePage = lazy(() => import('../Dynamic/CustomHomePage'));
const Header = lazy(() => import('../Header'));
const renderLoader = () => <p>Loading</p>;

const AppWrapper = styled.div`
  ${customStyles.AppWrapper}
`;

const StyledBg = styled(BackgroundImage)`
  ${customStyles.StyledBg}
  &:before,
  &:after {
    ${customStyles.StyledBg}
  }
`;

const Layout = ({ children }) => {
  const { setDocumentTitle, setHeadline } = useContext(StoreContext);
  const {
    config,
    alternateConfig1,
    alternateConfig2,
    alternateConfig3,
    alternateConfig4,
    siteImages,
    bannerImages,
  } = useLayoutQueries();
  const allImages = siteImages.edges;
  const backgroundImages = bannerImages.edges;
  const originalSiteLogo = allImages.find(image => {
    return image.node.fluid.originalName === 'logo.png';
  });
  const findFavicon = allImages.find(image => {
    return image.node.fluid.originalName === 'favicon.png';
  });
  const findGlobalImage = allImages.find(image => {
    return image.node.fluid.originalName === 'global.png';
  });
  const findSSLImage = allImages.find(image => {
    return image?.node.fluid.originalName === 'ssl-icon.png';
  });
  const findBlurredImage = allImages.find(image => {
    return image.node.fluid.originalName === 'blurred.png';
  });

  const [configState, setConfigState] = useState(config);

  const [versionUpdate, setVersionUpdate] = useState(false);
  useEffect(() => {
    const params = window?.location?.search;
    const parsedParams = parse(params);
    const jtkResult = window?.jtkResults;

    if (parsedParams?.version || jtkResult) {
      setVersionUpdate(true);

      if (
        (parsedParams?.version === '1' ||
          jtkResult?.result?.challenger === '1') &&
        alternateConfig1
      ) {
        const newHash = {};
        Object.keys(alternateConfig1).filter(el => {
          if (typeof alternateConfig1[el] === 'boolean') {
            // eslint-disable-next-line
            newHash[el] = alternateConfig1[el];
          } else if (alternateConfig1[el]) {
            // eslint-disable-next-line
            newHash[el] = alternateConfig1[el];
          }
        });

        setConfigState({ ...config, ...newHash });
      } else if (
        (parsedParams?.version === '2' ||
          jtkResult?.result?.challenger === '2') &&
        alternateConfig2
      ) {
        const newHash = {};
        Object.keys(alternateConfig2).filter(el => {
          if (typeof alternateConfig2[el] === 'boolean') {
            // eslint-disable-next-line
            newHash[el] = alternateConfig2[el];
          } else if (alternateConfig2[el]) {
            // eslint-disable-next-line
            newHash[el] = alternateConfig2[el];
          }
        });
        setConfigState({ ...config, ...newHash });
      } else if (
        (parsedParams?.version === '3' ||
          jtkResult?.result?.challenger === '3') &&
        alternateConfig3
      ) {
        const newHash = {};
        Object.keys(alternateConfig3).filter(el => {
          if (typeof alternateConfig3[el] === 'boolean') {
            // eslint-disable-next-line
            newHash[el] = alternateConfig3[el];
          } else if (alternateConfig3[el]) {
            // eslint-disable-next-line
            newHash[el] = alternateConfig3[el];
          }
        });
        setConfigState({ ...config, ...newHash });
      } else if (
        (parsedParams?.version === '4' ||
          jtkResult?.result?.challenger === '4') &&
        alternateConfig4
      ) {
        const newHash = {};
        Object.keys(alternateConfig4).filter(el => {
          if (typeof alternateConfig3[el] === 'boolean') {
            // eslint-disable-next-line
            newHash[el] = alternateConfig4[el];
          } else if (alternateConfig4[el]) {
            // eslint-disable-next-line
            newHash[el] = alternateConfig4[el];
          }
        });
        setConfigState({ ...config, ...newHash });
      } else {
        // catch all in case there's an issue with the version
        setConfigState(config);
      }
    } else {
      setConfigState(config);
    }
  }, [
    config,
    alternateConfig1,
    alternateConfig2,
    alternateConfig3,
    alternateConfig4,
  ]);

  useEffect(() => {
    if (configState.dynamicSites) {
      const url = document.URL;

      configState.dynamicSites.forEach(el => {
        if (url.includes(el.dynamicSurveyName) || versionUpdate) {
          if (el.favicon) {
            const findFav = allImages.find(
              e => e.node.fluid.originalName === el.favicon
            );

            const oldFav = document.querySelector('[rel="icon"]');
            document.head.removeChild(oldFav);

            const link = document.createElement('link');
            link.id = 'dynamic-favicon';
            link.rel = 'icon';
            link.href = findFav.node.fluid.src;
            document.head.appendChild(link);

            // update manifest icons
            const myDynamicManifest = {
              name: el.dynamicSurveyName,
              short_name: el.dynamicSurveyName,
              icons: [
                {
                  src: findFav.node.fluid.src,
                  sizes: '256x256',
                  type: 'image/png',
                },
              ],
            };
            const stringManifest = JSON.stringify(myDynamicManifest);
            const blob = new Blob([stringManifest], {
              type: 'application/json',
            });
            const manifestURL = URL.createObjectURL(blob);
            const findManifest = document.querySelector('[rel="manifest"]');
            findManifest.setAttribute('href', manifestURL);
          }
        }
      });
    }
  }, [allImages, configState, versionUpdate]);

  const globalImage = findGlobalImage.node.fluid;
  const sslIcon = findSSLImage?.node.fluid;
  const favicon = findFavicon.node.fluid;
  const [siteLogo, setSiteLogo] = useState(originalSiteLogo.node.fluid);
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const getSiteLogo = allImages.find(image => {
      return image.node.fluid.originalName === configState.siteLogo;
    });
    setSiteLogo(getSiteLogo?.node?.fluid);
  }, [allImages, configState.siteLogo]);

  const findSiteLogo = logo => {
    const getSiteLogo = allImages.find(image => {
      return image.node.fluid.originalName === logo;
    });

    if (getSiteLogo) {
      setSiteLogo(getSiteLogo.node.fluid);
    }
  };

  const setBackgroundImage = (img = configState.bgImage) => {
    const getBackgroundImage = backgroundImages.find(image => {
      return image.node.fluid.originalName === img;
    });
    setBgImage(getBackgroundImage?.node.fluid || '');
  };

  useEffect(() => {
    // wait for the redirect if vwo option
    setTimeout(() => {
      setBackgroundImage();
    }, 100);
    // eslint-disable-next-line
  }, [bannerImages, configState.bgImage]);

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      config: configState,
      globalImage,
      sslIcon,
      favicon,
      allImages,
    })
  );

  // Custom.js (for multiple domains)
  useEffect(() => {
    const query = parse(window.location.search);
    try {
      // customConfigInfo(config, setDocumentTitle, query, setHeadline);
      // eslint-disable-next-line
      const customConfig = require(`../../../data/sites/${process.env.GATSBY_SITE_TYPE}/${process.env.GATSBY_SITE_NAME}/custom.js`);
      if (customConfig?.default) {
        customConfig.default(configState, setDocumentTitle, query, setHeadline);
      }
    } catch {
      // Intentionally left blank, try block is checking if 'custom.js' file exists
    }
  }, [configState, setDocumentTitle, setHeadline]);

  return (
    <StoreProvider>
      <div
        id="siteOverlay"
        style={{
          background: `${
            window?.jtkResults?.result?.challenger ? '#fff' : 'transparent'
          }`,
        }}
      />
      <StoreContext.Consumer>
        {({
          activeTip,
          quizLength,
          disclosure,
          singlePageValidated,
          params,
          page,
          setParams,
          contextBgImage,
          values,
          setValues,
        }) => (
          <ConditionalWrapper
            condition={bgImage}
            wrapper={wrapperChildren => (
              <StyledBg
                fluid={bgImage || findBlurredImage.node.fluid}
                className={bgImage ? 'bgImage' : 'blurred-image'}
                id={bgImage ? 'background-image-main' : 'blurred-image-main'}
              >
                {wrapperChildren}
              </StyledBg>
            )}
          >
            <AppWrapper id="main-app" className={`main-app-page-${page}`}>
              <Suspense fallback={renderLoader()}>
                <GlobalStyle font={configState.fontText} />
                <Header
                  config={configState}
                  activeTip={activeTip}
                  id="header"
                  siteLogo={siteLogo}
                  globalImage={globalImage}
                  sslIcon={sslIcon}
                  findSiteLogo={findSiteLogo}
                  versionUpdate={versionUpdate}
                />
              </Suspense>
              <div id="gtmframe" />
              {childrenWithProps}
              {configState.customHomePage && (
                <CustomHomePage
                  config={configState}
                  page={page}
                  values={values}
                  setValues={setValues}
                  query={parse(window.location.search)}
                />
              )}
              <Suspense fallback={renderLoader()}>
                <AdditionalFooterContent config={configState} page={page} />
                <Footer
                  config={configState}
                  page={page}
                  quizLength={quizLength}
                  disclosure={disclosure}
                  singlePageValidated={singlePageValidated}
                  params={params}
                  setParams={setParams}
                  contextBgImage={contextBgImage}
                  setBackgroundImage={setBackgroundImage}
                />
              </Suspense>
            </AppWrapper>
          </ConditionalWrapper>
        )}
      </StoreContext.Consumer>
    </StoreProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
