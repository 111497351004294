import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { StoreContext } from './StoreContext';

const Seo = ({
  config: { title, surveyName, fontFamily, gTag },
  favicon,
  description = 'Home purchase/refinance form',
  lang,
  meta,
  keywords,
}) => {
  const { documentTitle } = useContext(StoreContext);

  if (!favicon) {
    throw new Error(`No Favicon Found For: ${surveyName}`);
  }

  return (
    <HelmetProvider>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={documentTitle || title}
        titleTemplate=""
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: description,
          },
          {
            name: `robots`,
            content: 'noindex, nofollow',
          },
          {
            name: `googlebot`,
            content: 'noindex, nofollow',
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                  name: `keywords`,
                  content: keywords.join(`, `),
                }
              : []
          )
          .concat(meta)}
        link={[
          {
            rel: 'shortcut icon',
            type: 'image/png',
            href: favicon.src,
            id: 'favicon',
          },
          {
            rel: 'preconnect',
            href: 'https://fonts.gstatic.com',
          },
          {
            rel: 'stylesheet',
            // rel: 'preconnect',
            as: 'font',
            href: `${fontFamily}`,
            crossOrigin: 'true',
          },
        ]}
      >
        <script
          async
          src="https://offers.printfingertech.net/js/jtksplits.js"
        />

        <script type="text/javascript">
          {`
            function gm_authFailure() {
              console.log("GOOGLE AUTH FAILURE");
              const findGoogleScript = document.querySelector('#address');

              if (address) {
                address.disabled = false;
                address.style.backgroundImage = 'none';
              }
            }`}
        </script>

        <script id="LeadiDscript" type="text/javascript">
          {`(function() {
              var s = document.createElement('script');
              s.id = 'LeadiDscript_campaign';
              s.type = 'text/javascript';
              s.async = true;
              s.src = '//create.lidstatic.com/campaign/f21bcfe7-421d-0b59-65fe-3d36e8d9c8d6.js?snippet_version=2&f=reset&callback=jornayaLeadId';
              var LeadiDscript = document.getElementById('LeadiDscript');
              LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
            })();`}
        </script>
        <script>
          {`setTimeout(function () {
              try {
                if (LeadiD) {
                  console.log("%c LeadiD.token = " + LeadiD.token, 'background: #FD5B78; color: #FFFFFF');
                }
              } catch (error) {
                // do nothing
              }
            }, 1500);`}
        </script>

        <noscript>
          {`<img
            src="/create.leadid.com/noscript.gif?lac=2b26b722-d668-ec71-d186-45fe6ec4dde1&lck=f21bcfe7-421d-0b59-65fe-3d36e8d9c8d6&snippet_version=2"
            alt=""
          />`}
        </noscript>

        <script async src="https://offers.printfingertech.net/js/jtknet.js" />
        <script
          async
          src="https://api.lincx.com/load"
          data-manual-render="true"
          data-zone-id="231260"
        />

        <script
          async
          src={
            gTag ? `https://www.googletagmanager.com/gtag/js?id=${gTag}` : ''
          }
        />
        <script>
          {gTag
            ? `window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', '${gTag}');`
            : ''}
        </script>

        <script
          type="text/javascript"
          src="//www.nextinsure.com/listingdisplay/loader/sh"
        />
      </Helmet>
    </HelmetProvider>
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  config: {},
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  config: PropTypes.object,
  favicon: PropTypes.object,
};

export default Seo;
