import 'promise-polyfill/src/polyfill';
import React, { lazy, Suspense, useState, useEffect } from 'react';
import { parse } from 'query-string';
import Layout from '../components/Containers/Layout';
import SEO from '../components/Seo';
import storage from '../utils/storage';
import { useLayoutQueries } from '../utils/queries';
import { hasSubdomainWithV2 } from '../utils/stagingSiteCheck';

// import { App } from '../components/App';

const App = lazy(() =>
  import('../components/App').then(module => ({ default: module.App }))
);

const renderLoader = () => '';

const loadScript = async url => {
  const script = document.createElement('script');
  script.src = url;
  script.async = false; // This is required for synchronous execution
  document.head.appendChild(script);
};

const IndexPage = () => {
  const { config } = useLayoutQueries();
  const [jtkSplitsLoaded, setJtkSplits] = useState(false);

  const isSSR = typeof window === 'undefined';

  // Need to load jtksplits here to prevent race conditions and get proper config/questions/styles
  useEffect(() => {
    loadScript('https://offers.printfingertech.net/js/jtksplits.js');

    if (!isSSR) {
      // eslint-disable-next-line prefer-destructuring
      const hostname = window.location.hostname;
      const params = window.location.search;
      const parsedParams = parse(params);
      // if (!parsedParams.version) {
      // (async () => {
      if (parsedParams.version) {
        storage('session', 'set', 'vwoVariation', parsedParams.version);
      }

      const jtkSplitsInterval = setInterval(async () => {
        if (typeof window?.jtksplits?.init === 'function') {
          /**
           * updates main config from dynamicSites config
           * @params1  {string} surveyName
           * @params2  {Bool} altStyle
           * @params3  {Bool} isTypTest
           * @params4  {string} route
           */
          const result = await window?.jtksplits?.init({
            siteUrl: hostname,
            siteVertical: config?.scData?.siteVertical,
            route: null,
            hasAltStyles: window?.altStyles,
            isTYP: false,
          });
          if (result?.result?.challenger) {
            storage('session', 'set', 'vwoVariation', result.result.challenger);
          }
          setTimeout(() => {
            setJtkSplits(true);
            clearInterval(jtkSplitsInterval);
          }, 500);
        }
      }, 500);
      setTimeout(() => {
        setJtkSplits(true);
        clearInterval(jtkSplitsInterval);
      }, 3000);

      return () => clearInterval(jtkSplitsInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !isSSR &&
    jtkSplitsLoaded && (
      <Suspense fallback={renderLoader()}>
        <Layout>
          <SEO keywords={[`gatsby`, `application`, `react`]} />
          <App />
        </Layout>
      </Suspense>
    )
  );
};

export default IndexPage;
